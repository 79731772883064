import React from 'react';
import { Alert, Modal, Form, Input, InputNumber, Select, message, Spin } from "antd";
import "./EditTaskModal.less";

const { Option } = Select;

class EditTaskModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
        }
        this.onFinish = this.onFinish.bind(this);
    }

    async onFinish(e) {
        await this.setState({
            loading: true,
            error: false
        });

        let errorMessage;
        try {
            let request = await fetch('/api/tasks/' + e.taskId, {
                method: "post",
                body: JSON.stringify({
                    status: e.status,
                    volume: e.volume,
                    comment: e.comment || null,
                    editor: this.props.user ? this.props.user.username : null
                }),
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": this.props.user['accessToken']
                }
            });

            if (request.status === 200) {
                message.success('Success');
                await this.setState({
                    loading: false,
                });
                
                this.props.onClose();
                if(this.props.callback){
                    this.props.callback();
                }
                
                return true;
            }

            errorMessage = request.message;
        } catch (err) {
            errorMessage = err.message;
        }

        await this.setState({
            error: errorMessage,
            loading: false
        });
        return false;
    };

    render() {
        const { taskId, visible, onClose } = this.props;
        const { loading, error } = this.state;

        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        };

        return (
            <Modal
                className={"task-edit-modal"}
                title="Edit"
                visible={visible}
                onCancel={onClose}
                okButtonProps={{ form: 'task-edit-modal-form', key: 'submit', htmlType: 'submit' }}
                destroyOnClose
            >
                <Spin spinning={loading}>
                    {
                        !!error &&
                        <Alert type={'error'} message={error} />
                    }
                    <Form id={'task-edit-modal-form'} {...layout} name="task-edit" onFinish={this.onFinish}>
                        <Form.Item
                            name={'taskId'}
                            initialValue={taskId}
                            hidden
                        >
                            <Input type={"hidden"} />
                        </Form.Item>
                        <Form.Item
                            label="Status"
                            name={'status'}
                            rules={[{ required: true, message: 'Status is required' }]}
                        >
                            <Select placeholder="Select status">
                                <Option value="success">success</Option>
                                <Option value="inProcess">inProcess</Option>
                                <Option value="error">error</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name={'volume'} label="Volume" rules={[{ type: 'number', message: 'Wrong volume', required: true, min: 0 }]}>
                            <InputNumber />
                        </Form.Item>
                        <Form.Item name={'comment'} label="Comment">
                            <Input.TextArea />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        )
    }
}

export default EditTaskModal;