import React from 'react';
import { Table, Tag, Typography } from "antd";
import "./Analytics.less";

const { Title } = Typography;

const columns = [
    {
        title: '',
        dataIndex: '1',
        key: '1',
        width: "300px"
    },
    {
        title: '',
        dataIndex: '2',
        key: '2',
    },
]

class Analytics extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data } = this.props;
        if (!data || !data.successfullyAnalyzed) {
            return null;
        }
        const dataSource = [];
        dataSource.push({
            key: "failedTxRealReward",
            "1": "Miner's real reward",
            "2": (data.failedTxRealReward || 0) + " ETH",
        })
        dataSource.push({
            key: "failedTxEfficiencyGwei",
            "1": "Tx efficiency",
            "2": (data.failedTxEfficiencyGwei || 0) + " gwei",
        })
        dataSource.push({
            key: "failedTxPossibleReward",
            "1": "Miner's reward in case of successful tx",
            "2": (data.failedTxPossibleReward || 0) + " ETH",
        })
        for (let i in data.competitionBundleInfos) {
            let tx = data.competitionBundleInfos[i];
            dataSource.push({
                key: "competitor" + (+i + 1),
                "1": "Competitor tx " + (+i + 1),
                "2": <>
                    <div><a href={'https://etherscan.io/tx/' + tx.txHash} target="_blank">{tx.txHash}</a></div>
                    <div>Sender: <a href={'https://etherscan.io/address/' + tx.sender} target="_blank">{tx.sender}</a></div>
                </>
            })
        }
        if (!!data.loseType) {
            dataSource.push({
                key: "loseType",
                "1": "Loose type",
                "2": <b>{data.loseType}</b>,
            })
        }
        dataSource.push({
            key: "competitorsReward",
            "1": "Miner's reward from competitor",
            "2": (data.competitorsReward || 0) + " ETH",
        })
        dataSource.push({
            key: "competitorsEfficiencyGwei",
            "1": "Competitor's efficiency",
            "2": (data.competitorsEfficiencyGwei || 0) + " gwei",
        })
        if (!data) {
            return null;
        }
        return (
            <div className={"analytics"}>
                <div className={"analytics-title"}>
                    <Title level={5}>Analytics</Title>
                </div>
                <Table className={"analytics-table"} size="small" showHeader={false} pagination={false} columns={columns} dataSource={dataSource} />

            </div>
        )
    }
}

export default Analytics;