import React from 'react';
import { Drawer, Table } from "antd";
import "./VolumeEstimationsDrawer.less";

const columns = [{
    title: "Volume",
    dataIndex: "volume",
    key: "volume"
}, {
    title: "Profit",
    dataIndex: "profit",
    key: "profit"
}];

class VolumeEstimationsDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false,
        }
    }

    render() {
        const { volumeEstimations, tradingAmountFrom, visible, onClose, contRef } = this.props;
        const { error } = this.state;

        let dataSource = [];
        let active;

        for (let i in volumeEstimations) {
            let volumeEstimation = volumeEstimations[i]
            try {
                if (tradingAmountFrom >= volumeEstimation[0]) {
                    active = 've' + i;
                }
            } catch (e) { }
            dataSource.push({
                key: 've' + i,
                volume: volumeEstimation[0],
                profit: volumeEstimation[1].toFixed(6),
                active: false
            });
        }

        if (active && dataSource.length) {
            dataSource = dataSource.map(row => {
                if (row.key === active) {
                    row['active'] = true
                }
                return row
            })
        }

        return (
            <Drawer
                placement="right"
                closable={true}
                onClose={onClose}
                visible={visible}
                width={300}
                getContainer={contRef.current}
                style={{ position: "absolute" }}
            >
                <Table
                    className={"volume-estimations"}
                    rowClassName={(row) => row.active ? 'active' : ''}
                    dataSource={dataSource}
                    columns={columns}
                    size="small"
                    pagination={false}
                />
            </Drawer>
        )
    }
}

export default VolumeEstimationsDrawer;