import React from 'react';
import { Table, Tag, Typography } from "antd";
import "./SubmittedTransactions.less";

const { Title } = Typography;

const columns = [
    {
        title: 'Tx hash',
        dataIndex: 'hash',
        key: 'hash',
        render: (hash) => !!hash ? <a href={"https://etherscan.io/tx/" + hash} target="_blank">{hash.substr(0, 8)}...{hash.substr(-8)}</a> : null,
        width: '200px'
    },
    {
        title: '',
        dataIndex: 'competitor',
        key: 'competitor',
        width: '150px'
    },
    {
        title: 'Priority fee, gwei',
        dataIndex: 'gasPrice',
        key: 'gasPrice',
    },
    {
        title: 'Is private tx',
        dataIndex: 'isPrivateTx',
        key: 'isPrivateTx',
    },
    {
        title: 'Private tx hash',
        dataIndex: 'privateTxHash',
        key: 'privateTxHash',
        render: (hash) => !!hash ? <a href={"https://etherscan.io/tx/" + hash} target="_blank">{hash.substr(0, 8)}...{hash.substr(-8)}</a> : null
    }
]

class SubmittedTransactions extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { ethCompetitorTransactions, ethSubmittedTradeResponses } = this.props;

        const data = [];
        for (let i in ethCompetitorTransactions) {
            let tx = ethCompetitorTransactions[i];
            data.push({
                key: "ct-" + (Number(i) + 1),
                hash: tx.txHash,
                competitor: "Competitor",
                gasPrice: tx.gasGwei,
            })
        }
        for (let i in ethSubmittedTradeResponses) {
            let tx = ethSubmittedTradeResponses[i];
            data.push({
                key: "st-" + (Number(i) + 1),
                hash: tx.txHash,
                competitor: null,
                isPrivateTx: tx.isPrivateTx ? "TRUE": null,
                gasPrice: tx.gasGwei,
                privateTxHash: tx.privateTxHash
            })
        }

        data.sort((a, b) => a.gasPrice > b.gasPrice ? 1 : -1);

        return (
            <div className={"submitted-transactions"}>
                <div className={"submitted-transactions-title"}>
                    <Title level={5}>Submitted transactions</Title>
                </div>
                <Table className={"submitted-transactions-table"} size="small" pagination={false} columns={columns} dataSource={data} />
            </div>
        )
    }
}

export default SubmittedTransactions;