import {LoadingOutlined} from "@ant-design/icons";
import "./TasksDailyChart.less";
import {Button, DatePicker, PageHeader, Select, Spin} from "antd";
import axios from "axios";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {Bar, CartesianGrid, ComposedChart, Label, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {TIMEZONE_OFFSET} from "../../../../constants/timeOffset";
import CustomGraphOptionsModal from "./CustomGraphOptionsModal";

const ERROR_TYPES_TO_IGNORE = ['ourTransactionMined']

const {Option} = Select;
const {RangePicker} = DatePicker;
const dateFormat = "DD.MM.YYYY";

class TasksDailyChart extends React.Component {
  mount;
  isOpenedVolumes = false;
  controller = new AbortController();
  currencies = ["ETH", "USDT", "USDC", "BNB", "SOL"];

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      error: false,
      datePickerValue: this.getDefaultDateRange(),
      iterableCurrencies: this.currencies,
      filteredByTriggerType: [],
      filteredByExchangeType: [],
      filteredByCurrencies: [],
      filteredByStrategies: [],
      addGraph: false,
      additionalGraphs: [],
    };
    this.handleFilterByExchangeType = this.handleFilterByExchangeType.bind(this);
    this.handleFilterByCurrencies = this.handleFilterByCurrencies.bind(this);
    this.handleFilterByTriggerType = this.handleFilterByTriggerType.bind(this);
    this.handleFilterByStrategies = this.handleFilterByStrategies.bind(this);
    this.onChangeDatePicker = this.onChangeDatePicker.bind(this);
    this.toggleVolumes = this.toggleVolumes.bind(this);
    this.initAddGraph = this.initAddGraph.bind(this);
    this.createNewGraph = this.createNewGraph.bind(this);
    this.getCustomGraphData = this.getCustomGraphData.bind(this);
    this.refreshGraphInfo = this.refreshGraphInfo.bind(this);
    this.deleteCustomGraph = this.deleteCustomGraph.bind(this);
    this.CustomTooltip = this.CustomTooltip.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
  }

  componentDidMount() {
    this.mount = true;
    this.getTasksDailyStatistics();
  }

  componentWillUnmount() {
    this.controller.abort();
    this.mount = false;
  }

  getDefaultDateRange() {
    return [
      moment().subtract(30, "days").startOf("day"),
      moment().endOf("day"),
    ];
  }

  CustomTooltip({active, payload, label, data}) {
    const [tooltipContent, setTooltipContent] = useState(null);
    const {filteredByCurrencies} = this.state;

    useEffect(() => {
      let iterableCurr = [];

      if (filteredByCurrencies.length > 0) {
        iterableCurr = filteredByCurrencies;
      } else {
        iterableCurr = this.currencies;
      }

      const countAndRenderTooltipContent = (data, label) => {
        const precision = 3;
        const coinSources = {};
        const tasks = {};
        const profits = {};
        const gases = {};
        const tooltipContent = [];

        const partData = data.forday;
        const totals = data.totals;
        
        iterableCurr.map(curr => {
          coinSources[curr] = `/public/imgs/${curr.toLowerCase()}.svg`;
          if (!partData[curr]) return;

          partData[curr].map(currData => {
            if (currData.name !== label) {
              return;
            }
            const item = currData;
            if (item) {
              tasks[curr] = {};
              tasks[curr].success = item.success;
              tasks[curr].error = item.error;
              tasks[curr].count = item.success + item.error;

              profits[curr] = {};
              profits[curr].profit = item.profit;
              profits[curr].estimatedProfit = item.estimatedProfit;
              profits[curr].realToEstimatedProfit = item.realToEstimatedProfit;

              gases[curr] = {};
              gases[curr].minGasPrice = item.minGasPrice;
              gases[curr].maxGasPrice = item.maxGasPrice;
              gases[curr].avgGasPrice = item.avgGasPrice;

              tooltipContent.push(
                <div key={curr} style={{marginBottom: "10px"}}>
                  <div>
                    <b>Profit</b> <img className={"coin-icon"} src={coinSources[curr]} alt={curr} /> -
                    real: <b>{profits[curr].profit.toFixed(precision) || 0}</b>,
                    expected: <b>{profits[curr].estimatedProfit.toFixed(precision)}</b>,
                    ratio: {profits[curr].realToEstimatedProfit}%
                  </div>
                  <div>
                    <b>Tasks: {tasks[curr].count}</b> -
                    <span
                      style={{color: "#389e0d"}}> success: <b>{tasks[curr].success}</b></span><i>({(tasks[curr].success *
                    100 /
                    tasks[curr].count).toFixed()}%)</i>,
                    <span style={{color: "#ff7066"}}> error: <b>{tasks[curr].error}</b></span>
                    <i>({(tasks[curr].error * 100 / tasks[curr].count).toFixed()}%)</i>
                  </div>
                  <div>
                    <b>Gas price</b> - max: <b>{gases[curr].maxGasPrice}</b>,
                    min: <b>{gases[curr].minGasPrice}</b>,
                    avg: <b>{gases[curr].avgGasPrice}</b>
                  </div>
                </div>,
              );
            }
          });
        });

        if (tooltipContent.length > 0) {
          tooltipContent.push(
            <div key={label} style={{marginBottom: "10px"}}>
              <div>
                <b>Profit <span style={{color: "#267c00"}}>$</span></b> -
                real: <b>{totals[label].profit.toFixed(1) || 0}</b>,
                expected: <b>{totals[label].estimatedProfit.toFixed(1)}</b>,
                ratio: {Number((totals[label].profit * 100 / totals[label].estimatedProfit).toFixed(2))}%
              </div>
              <div>
                <b>Tasks: {totals[label].totalTasks}</b> -
                <span
                  style={{color: "#389e0d"}}> success:
                  <b>{totals[label].success}</b></span><i>({(totals[label].success * 100 /
                totals[label].totalTasks).toFixed()}%)</i>,
                <span style={{color: "#ff7066"}}> error:
                  <b>{totals[label].error}</b></span><i>({(totals[label].error * 100 /
                totals[label].totalTasks).toFixed()}%)</i>
              </div>
            </div>,
          );
        }

        return tooltipContent;
      };

      if (active && data.forday && data.totals) {
        const tooltip = countAndRenderTooltipContent(data, label);
        setTooltipContent(tooltip);
      }
    }, [active, data, label]);

    return (
      <div className="custom-tooltip">
        <p className="tooltip-label">{label}</p>
        <div className="tooltip-body">
          {tooltipContent}
        </div>
      </div>
    );
  };

  async getRebalances(date, curr) {
    try {
      let timeFrom = new Date(date);
      timeFrom.setHours(0, 0, 0, 0);

      let timeTo = new Date(date);
      timeTo.setHours(23, 59, 59, 999);

      let response = await axios(
        `/api/rebalances?currency=${curr}&from=${timeFrom.getTime()}&to=${timeTo.getTime()}`, {
          headers: {
            "x-access-token": this.props.user["accessToken"],
          },
        });

      if (response.status !== 200) {
        throw new Error("Failed to get rebalances");
      }

      return response.data;
    } catch (e) {
      console.log(e);
    }
  }

  async getCurrencyPricesInUsdt() {
    try {
      const response = await axios(`/api/currencies/prices`, {
        headers: {
          "x-access-token": this.props.user["accessToken"],
        },
      });

      return response.data;
    } catch (e) {
      console.log(e);
      if (this.mount) {
        this.setState({error: e.message});
      }
      return null;
    }
  }

  initGeneralDataArray(datePickerValue1, datePickerValue2) {
    const data = {};
    const startDate = moment(datePickerValue1);
    const endDate = moment(datePickerValue2);
    const diffInDays = endDate.diff(startDate, "days");

    for (let i = 0; i <= diffInDays; i++) {
      const currentDate = startDate.clone().add(i, "days").startOf("day").toDate();
      data[currentDate] = {
        name: currentDate.toLocaleDateString(),
        success: 0,
        error: 0,
        profit: 0,
        estimatedProfit: 0,
      };
    }

    return data;
  }

  initTotalsDataArray(datePickerValue1, datePickerValue2) {
    const data = {};
    const startDate = moment(datePickerValue1);
    const endDate = moment(datePickerValue2);
    const diffInDays = endDate.diff(startDate, "days");

    for (let i = 0; i <= diffInDays; i++) {
      const currentDate = startDate.clone().add(i, "days").startOf("day").toDate().toLocaleDateString();
      data[currentDate] = {
        totalTasks: 0,
        success: 0,
        error: 0,
        profit: 0,
        estimatedProfit: 0,
      };
    }

    return data;
  }

  async getTasksDailyStatistics() {
    const {loading, datePickerValue, filteredByTriggerType, filteredByExchangeType, filteredByCurrencies, filteredByStrategies} = this.state;
    const prices = await this.getCurrencyPricesInUsdt();
    let generalData = this.initGeneralDataArray(datePickerValue[0], datePickerValue[1]);
    let totals = this.initTotalsDataArray(datePickerValue[0], datePickerValue[1]);

    if (loading === true) {
      return false;
    }

    let timeFrom = moment(datePickerValue[0]);
    let timeTo = moment(datePickerValue[1]);

    if (this.mount) {
      this.props.onLoadingChange(true);
      this.setState({loading: true, error: false});
    } else {
      return;
    }

    try {
      let data = {};
      let iterableCurr = [];

      if (filteredByCurrencies.length > 0) {
        iterableCurr = filteredByCurrencies;
      } else {
        iterableCurr = this.currencies;
      }

      await Promise.all(iterableCurr.map(async (curr) => {
        data[curr] = [];
        let url = `/api/tasks/statistics/count/daily?currency=${curr}&from_timestamp=${(timeFrom /
            1000).toFixed()}&to_timestamp=${(timeTo / 1000).toFixed()}` +
            `&timezoneOffset=${TIMEZONE_OFFSET}` +
            `${filteredByTriggerType.length > 0 ? "&triggerTypes=" + filteredByTriggerType.join(",") : ""}` +
            `${filteredByExchangeType.length > 0 ? "&exchangesFilter=" + filteredByExchangeType.join(",") : ""}` +
            `${filteredByStrategies.length > 0 ? "&strategiesFilter=" + filteredByStrategies.join(",") : ""}` + 
            `${`&errorTypesToIgnore=${ERROR_TYPES_TO_IGNORE.join(",")}`}`;

        let statistics = await fetch(url
          , {
            signal: this.controller.signal,
            headers: {
              "x-access-token": this.props.user["accessToken"],
            },
          });
        statistics = await statistics.json();

        if (statistics) {
          for (let i in statistics) {
            let raw = statistics[i];
            let dateStr = new Date();
            dateStr.setFullYear(raw.y);
            dateStr.setMonth(raw.m - 1);
            dateStr.setDate(raw.d);
            dateStr.setHours(0, 0, 0, 0);

            if (dateStr.getMonth() !== raw.m - 1) {
                dateStr.setDate(1);
                dateStr.setMonth(raw.m);
            }

            let rebalancesForDay = await this.getRebalances(dateStr, curr);
            const rebalanceFeeForDay = rebalancesForDay.reduce((sum, tx) => {
              sum += tx.fee;
              return sum;
            }, 0);

            let realProfit = raw.profit ? Number(raw.profit.toFixed(4)) : 0;
            let estimatedProfit = raw.estimatedProfit ? Number(raw.estimatedProfit.toFixed(4)) : 0;

            let name = dateStr.toLocaleDateString();
            let entry = data[curr].find(e => e.name === name);
            let entryExists = !!entry
            if (!entryExists){
              entry = {};
            }

            entry.name = name;
            entry.success = (entry.success || 0) + (raw.success || 0);
            entry.error = (entry.error || 0) + (raw.error || 0);
            entry.profit = (entry.profit || 0) + (realProfit - rebalanceFeeForDay);
            entry.estimatedProfit = (entry.estimatedProfit || 0) + (estimatedProfit - rebalanceFeeForDay);
            entry.realToEstimatedProfit = Number((entry.profit * 100 / entry.estimatedProfit).toFixed(2));
            entry.maxGasPrice = Math.max(entry.maxGasPrice || 0, raw.maxGasPrice ? Number(raw.maxGasPrice.toFixed(4)) : 0);
            entry.minGasPrice = Math.min(entry.minGasPrice ?? Infinity, raw.minGasPrice ? Number(raw.minGasPrice.toFixed(4)) : Infinity);
            entry.avgGasPrice = ((entry.avgGasPrice || 0) + (raw.avgGasPrice ? Number(raw.avgGasPrice.toFixed(4)) : 0)) / 2;

            if (!entryExists){
              data[curr].push(entry);
            }

            const key = dateStr.toLocaleDateString();

            if(!totals[key]) {
              continue;
            }

            totals[key].totalTasks += (raw.success || 0) + (raw.error || 0);
            totals[key].success += (raw.success || 0);
            totals[key].error += (raw.error || 0);
            totals[key].profit += Number((realProfit - rebalanceFeeForDay) * +prices[curr]);
            totals[key].estimatedProfit += Number((estimatedProfit - rebalanceFeeForDay) * +prices[curr]);

            generalData[dateStr].success += (raw.success || 0);
            generalData[dateStr].error += (raw.error || 0);
            generalData[dateStr].profit += Number((realProfit - rebalanceFeeForDay) * +prices[curr]);
            generalData[dateStr].estimatedProfit += Number((estimatedProfit - rebalanceFeeForDay) * +prices[curr]);
          }
        }
      }));

      generalData = Object.values(generalData)
        .map(({name, success, error, profit, estimatedProfit}) => ({name, success, error, profit, estimatedProfit}));
      if (this.mount) {
        this.props.onLoadingChange(false);
        this.setState({data, generalData, loading: false, totals});
      }
    } catch (e) {
      console.log(e);
      if (this.mount) {
        this.setState({error: e.message});
      }
    }
    if (this.mount) {
      this.props.onLoadingChange(false);
      this.setState({loading: false});
    }
  }

  async onChangeDatePicker(value) {
    value[0] = value[0].startOf("day");
    value[1] = value[1].endOf("day");
    if (this.mount) {
      await this.setState({datePickerValue: value});
    }
  }

  async applyFilters() {
    await this.getTasksDailyStatistics();
  }

  handleFilterByTriggerType(value) {
    if (this.mount) {
      this.setState({filteredByTriggerType: value});
    }
  };

  handleFilterByStrategies(value) {
    if (this.mount) {
      this.setState({filteredByStrategies: value});
    }
  };

  handleFilterByExchangeType(value) {
    if (this.mount) {
      this.setState({filteredByExchangeType: value});
    }
  };

  handleFilterByCurrencies(value) {
    if (this.mount) {
      this.setState({filteredByCurrencies: value});
    }
  };

  toggleVolumes() {
    this.isOpenedVolumes = !this.isOpenedVolumes;
    if (this.mount) {
      this.setState({openedVolumes: this.isOpenedVolumes});
    }
  }

  initAddGraph() {
    this.setState({
      addGraph: !this.state.addGraph,
    });
  }

  createNewGraph(e) {
    if (!e.key) {
      return;
    }

    this.getCustomGraphData(e.name, e.key, e.filters).then(graphData => {
      if (!graphData) {
        return;
      }
      const graphs = [...this.state.additionalGraphs];
      graphs.push({
        id: this.state.additionalGraphs.length + 1,
        name: e.name,
        key: e.key,
        filters: e.filters,
        data: graphData.plotData?.map(plot => {
          const date = moment(plot.date).format("DD.MM.YYYY");
          return {date, value: plot.value};
        }) || [],
      });
      this.setState({
        additionalGraphs: graphs,
      });
    });
  }

  async refreshGraphInfo() {
    const {additionalGraphs} = this.state;
    for (let graph of additionalGraphs) {
      await this.getCustomGraphData(graph.name, graph.key, graph.filters).then(graphData => {
        additionalGraphs.find(g => g.id === graph.id).data = graphData.plotData?.map(plot => {
          const date = moment(plot.date).format("DD.MM.YYYY");
          return {date, value: plot.value};
        }) || [];
      });

    }
    if (this.mount) {
      this.setState({
        additionalGraphs: [...additionalGraphs],
      });
    }
  }

  async getCustomGraphData(name, key, filters) {
    const {datePickerValue} = this.state;

    let timeFrom = moment(datePickerValue[0])
      .subtract(moment(datePickerValue[1]).utcOffset(), "hours")
      .toDate()
      .getTime();
    let timeTo = moment(datePickerValue[1])
      .subtract(moment(datePickerValue[1]).utcOffset(), "hours")
      .toDate()
      .getTime();
    let params = "";
    if (filters) {
      Object.keys(filters).forEach(filter => {
        params += "&" + filter + "=" + filters[filter]?.toString();
      });
    }
    try {
      const customGraphData = await fetch(
        `/api/tasks/customPlot?dateFrom=${timeFrom}&dateTo=${timeTo}&plotName=${key}${params}`,
        {
          signal: this.controller.signal,
          headers: {
            "x-access-token": this.props.user["accessToken"],
          },
        });

      if (customGraphData.status === 200) {
        return await customGraphData.json();
      }
    } catch (e) {
      if (e.name === "AbortError") return;
      return null;
    }

  }

  deleteCustomGraph(graph) {
    this.setState({
      additionalGraphs: this.state.additionalGraphs.filter(g => g.id !== graph.id),
    });
  }

  render() {
    const {
      data,
      generalData,
      totals,
      loading,
      datePickerValue,
      addGraph,
      additionalGraphs,
      iterableCurrencies,
    } = this.state;

    const fullData = {};
    fullData.forday = data;
    fullData.totals = totals;

    const checkIcon = "/public/imgs/checkmark.svg";

    return (
      <div className={"tasks-daily-chart"}>
        <PageHeader
          key={"daily-statistic-graph-header"}
          className="site-page-header"
          backIcon={false}
          title="Daily task statistics"
          loading={loading}
          extra={[
            <Spin
              key={"spin"}
              size="small"
              spinning={loading}
              style={{marginRight: 10}}
              indicator={<LoadingOutlined style={{fontSize: 20}} spin />}
            />,
            <img alt="ckechIcon" src={checkIcon} key="check-icon" onClick={this.applyFilters}
                 style={{width: "30px", height: "30px", cursor: "pointer"}} />,
            <Select
              key={"filter-by-currencies"}
              className={"filter-by-currencies"}
              mode="multiple"
              allowClear
              placeholder="Filter by currencies"
              defaultValue={[]}
              disabled={loading}
              onChange={this.handleFilterByCurrencies}
              maxTagCount={"responsive"}
            >
              {
                iterableCurrencies.map(type => <Option key={type}>{type}</Option>)
              }
            </Select>,
            <RangePicker
              key={"range-picker"}
              showTime={false}
              disabled={loading}
              format={dateFormat}
              defaultValue={datePickerValue}
              onCalendarChange={this.onChangeDatePicker}
            />,
          ]}
          style={{marginBottom: "25px"}}
        >
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              width={500}
              height={500}
              data={generalData?.map(
                ({name, success, error, profit, estimatedProfit}) => ({name, success, error, profit, estimatedProfit}))}
              margin={{
                top: 20,
                right: 10,
                left: 0,
                bottom: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name">
                <Label value="Date" offset={-10} position="insideBottom" />
              </XAxis>
              <YAxis yAxisId="left" orientation="left">
                <Label value="Tasks" offset={30} position="bottom" />
              </YAxis>
              <YAxis yAxisId="right" orientation="right">
                <Label value="Profit" offset={30} position="bottom" />
              </YAxis>
              <Tooltip content={<this.CustomTooltip data={fullData} />} wrapperStyle={{zIndex: 1000}} />
              <Bar dataKey="error" yAxisId="left" stackId="a" fill="#ff7066" />
              <Bar dataKey="success" yAxisId="left" stackId="a" fill="#3395ff" label={{position: "top"}} />
              <Line dataKey="profit" type="monotone" yAxisId="right" fill="#000" dot={false} />
              {/*<Line dataKey="estimatedProfit" type="monotone" yAxisId="right" fill="#ffd766" />*/}
            </ComposedChart>
          </ResponsiveContainer>
        </PageHeader>
        {
          additionalGraphs.map(graph =>
            <PageHeader
              className="site-page-header"
              backIcon={false}
              title={graph.name}
              key={"custom-graphs-" + graph.id}
              extra={[
                <Button
                  className={"additional-graph-delete"}
                  key="toggle"
                  shape="circle"
                  disabled={loading}
                  onClick={_ => this.deleteCustomGraph(graph)}>
                  .
                </Button>,
              ]}
            >
              <ResponsiveContainer width="100%" height="100%">
                <ComposedChart
                  width={500}
                  height={500}
                  data={graph.data}
                  margin={{
                    top: 20,
                    right: 10,
                    left: 0,
                    bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="5 5" />
                  <XAxis dataKey="date" xAxisId={1}>
                    <Label value="Date" offset={-10} position="insideBottom" />
                  </XAxis>
                  <YAxis yAxisId="right" orientation="right" scale={"linear"} type="number">
                    <Label value="Value" offset={30} position="bottom" />
                  </YAxis>
                  <YAxis yAxisId="left" orientation="left" scale={"linear"} type="number">
                    <Label value="Value" offset={30} position="bottom" />
                  </YAxis>
                  <Bar dataKey="value" xAxisId={1} yAxisId="right" stackId="a" fill="#ff7066" />
                </ComposedChart>
              </ResponsiveContainer>
            </PageHeader>,
          )
        }
        <Button type={"primary"} className={"refresh-icon"} disabled={loading} onClick={_ => this.initAddGraph()}>
          + Add custom graph
        </Button>
        {
          addGraph &&
          <CustomGraphOptionsModal visible={!!addGraph}
                                   onClose={this.initAddGraph}
                                   callback={e => this.createNewGraph(e)}></CustomGraphOptionsModal>
        }
      </div>
    );
  }
}

export default TasksDailyChart;
