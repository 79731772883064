import React from 'react';
import { Form, Button, Alert, Input, Drawer, message } from "antd";
import "./AddConfigDrawer.less";

class AddConfigDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
        }

        this.onSubmit = this.onSubmit.bind(this)
    }

    async onSubmit(values) {
        this.setState({ loading: true, error: false });
        try {
            let response = await fetch('/api/console/config', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    "x-access-token": this.props.user['accessToken']
                },
                body: JSON.stringify({
                    name: values.name,
                    value: values.value,
                    note: values.note || null,
                })
            });
            if (response.status !== 200) {
                response = await response.json();
                this.setState({ error: response.message });
            } else {
                await this.props.onClose();
                this.props.callback();
                message.success('Config added successfully');
            }
        } catch (e) {
            console.log(e);
            this.setState({ error: e.message });
        }
        this.setState({ loading: false });
    }

    render() {
        let { error, loading } = this.state;
        const { visible, onClose } = this.props;
        
        return (
            <Drawer
                title="Add"
                width={500}
                onClose={onClose}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
            >
                {
                    !!error &&
                    <Alert message={error} type="error" banner />
                }
                <Form id={'add-exchange-form'} name="add-exchange" layout="vertical" hideRequiredMark onFinish={this.onSubmit}>
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please enter name' }]}
                    >
                        <Input placeholder="Please enter name" />
                    </Form.Item>
                    <Form.Item
                        name="value"
                        label="Value"
                        rules={[{ required: true, message: 'Please enter value' }]}
                    >
                        <Input placeholder="Please enter value" />
                    </Form.Item>
                    <Form.Item
                        name="note"
                        label="Note"
                    >
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType={"submit"} style={{ float: 'right' }} type="primary" disabled={loading}>
                            Add
                        </Button>
                    </Form.Item>
                </Form>
            </Drawer>
        )
    }
}

export default AddConfigDrawer;