import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { PageHeader, Typography, Tabs, Row, Col, Divider } from 'antd';
import axios from 'axios';
import moment from 'moment';
import VolumeChart from './components/VolumeChart.jsx';
import FeeChart from './components/FeeChart.jsx';
import GasAndBribeChart from './components/GasAndBribeChart.jsx';
import './Volumes.less';

const { Title } = Typography;

const daysRange = [1, 7, 30, 90, 180];

const Volumes = ({ user }) => {
  const [tokenPrices, setTokenPrices] = useState(null);

  useEffect(() => {
    getTokenPrices();
  }, []);

  const getDateDaysBeforeNow = (days) => {
    return moment().subtract(days, 'days');
  };

  const getTokenPrices = async () => {
    try {
      const response = await axios(`/api/currencies/prices`, {
        headers: {
          'x-access-token': user['accessToken'],
        },
      });

      setTokenPrices(response.data);
    } catch (e) {
      console.error(ex);
    }
  };

  const tabItems = daysRange.map((days) => ({
    key: days.toString(),
    label: `${days} day`,
    children: (
      <Row gutter={40}>
        <Col xxl={12} sm={24} xs={24}>
          <VolumeChart user={user} from={getDateDaysBeforeNow(days)} tokenPrices={tokenPrices} />
        </Col>
        <Divider type='vertical' className='custom-divider' />
        <Col xxl={12} sm={24} xs={24}>
          <FeeChart user={user} from={getDateDaysBeforeNow(days)} tokenPrices={tokenPrices} />
        </Col>
      </Row>
    ),
  }));

  return (
    <>
      <PageHeader className='main-page-content' title={<Title>Volumes</Title>} />

      <Tabs
        defaultActiveKey={daysRange[0].toString()}
        type='card'
        size='large'
        style={{ marginBottom: 0 }}
        items={tabItems}
      />

      <GasAndBribeChart
        user={user}
        from={getDateDaysBeforeNow(7)}
        to={getDateDaysBeforeNow(0)}
        tokenPrices={tokenPrices}
      />
    </>
  );
};

export default withRouter(Volumes);
