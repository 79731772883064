import { Card, PageHeader, Skeleton, Button, Collapse } from 'antd';
import { ArrowRightOutlined, ArrowDownOutlined } from '@ant-design/icons';
import './BalancesCard.less';
import axios from 'axios';
import React from 'react';

const { Panel } = Collapse;

class BalancesCard extends React.Component {
  mount;
  currencies = ['ETH', 'USDT', 'USDC', 'BNB', 'SOL'];
  mmCurrencies = ['AURORA', 'SQR', 'SSNC', 'TIME-2', 'KLAUS'];
  allCurrencies = this.currencies.concat(this.mmCurrencies);
  exchangePrefixesForCollaps = ['Eth'];

  constructor(props) {
    super(props);
    this.state = {
      balances: {},
      inProgressCounts: {},
      inProgressAmounts: {},
      loading: false,
      mmLoading: false,
      error: false,
      expanded: {},
    };

    this.reload = this.reload.bind(this);
    this.toggleExpand = this.toggleExpand.bind(this);
  }

  componentDidMount() {
    this.mount = true;
    this.loadBalances(this.currencies, false);
    this.loadBalances(this.mmCurrencies, true);
  }

  componentWillUnmount() {
    this.mount = false;
  }

  reload(e, currencies, isMMCurrency) {
    e.preventDefault();
    this.loadBalances(currencies, isMMCurrency);
  }

  toggleExpand(exchangePrefix) {
    this.setState((prevState) => ({
      expanded: {
        ...prevState.expanded,
        [exchangePrefix]: !prevState.expanded[exchangePrefix],
      },
    }));
  }

  async loadBalances(currencies, isMMCurrency) {
    let { balances } = this.state;
    const { exchanges } = this.props;

    if (!isMMCurrency ? this.state.loading === true : this.state.mmLoading) {
      return;
    }

    if (!isMMCurrency) {
      this.setState({ loading: true });
    } else {
      this.setState({ mmLoading: true });
    }

    await this.updateTasksInProgress(currencies);

    await Promise.all(
      exchanges.map(async (exchange) => {
        balances[exchange.exchangeId] ||= {};
        await Promise.all(
          currencies.map(async (currency) => {
            if (this.shouldIgnore(exchange.exchangeId, currency)) return;

            balances[exchange.exchangeId].exchangeName = exchange.exchangeName;
            balances[exchange.exchangeId][currency] = {
              balance: 0,
              hasError: false,
            };

            try {
              let response = await axios(`/api/balance/${exchange.exchangeId}?currency=${currency}`, {
                headers: {
                  'x-access-token': this.props.user['accessToken'],
                },
              });

              if (response.status !== 200 || !response.data.hasOwnProperty('volume')) {
                throw new Error('Failed to get balance from ' + exchange.exchangeId);
              }

              balances[exchange.exchangeId][currency].balance = response.data.volume;
            } catch (e) {
              console.log(e);
              balances[exchange.exchangeId][currency].hasError = true;
            }
          })
        );
      })
    );

    if (this.mount) {
      this.setState({ balances });

      if (!isMMCurrency) {
        this.setState({ loading: false });
      } else {
        this.setState({ mmLoading: false });
      }
    }
  }

  async updateTasksInProgress(currencies) {
    let { inProgressCounts, inProgressAmounts } = this.state;
    try {
      const promises = currencies.map(async (currency) => {
        const response = await axios(`/api/tasks?currency=${currency}&limit=1000`, {
          headers: {
            'x-access-token': this.props.user['accessToken'],
          },
        });

        let tasks = response.data.filter((task) => task).filter((task) => task.status === 'inProgress');

        const inProgressAmount = tasks.reduce((sum, { actualAmountFrom }) => sum + actualAmountFrom, 0);

        inProgressCounts[currency] = tasks.length;

        return { currency, inProgressAmount };
      });
      (await Promise.all(promises)).forEach(({ currency, inProgressAmount }) => {
        inProgressAmounts[currency] = inProgressAmount;
      });

      if (this.mount) {
        this.setState({ inProgressCounts, inProgressAmounts });
      }
    } catch (e) {
      console.log(e);
      if (this.mount) {
        this.setState({ error: e.message });
      }
    }
  }

  sortExchanges(exchanges) {
    return exchanges.sort((a, b) => b.exchangeName.startsWith('Uniswap') - a.exchangeName.startsWith('Uniswap'));
  }

  getBalanceRow(currencies, balance, rowNumber, precision) {
    return (
      <tr key={'balance-' + balance + '-' + rowNumber}>
        <td className='balance-exchange-name' style={{ fontSize: '12px' }}>
          {balance && balance.exchangeName}
        </td>
        {currencies.map((currency) => (
          <td key={currency} style={{ fontSize: '12px' }}>
            {balance[currency] ? (
              !balance[currency].hasError ? (
                +balance[currency].balance.toFixed(currency === 'ETH' ? 2 : precision) + ``
              ) : (
                <b>Error!</b>
              )
            ) : (
              `0`
            )}
          </td>
        ))}
      </tr>
    );
  }

  getBalanceTotalByExcPrefix(currencies, balances, excPrefix) {
    let prefixTotals = {};
    for (let exId in balances) {
      if (exId.startsWith(excPrefix.toLowerCase())) {
        currencies.forEach((currency) => {
          if (balances[exId]?.[currency] ?? balances[exId][currency]) {
            prefixTotals[currency] = prefixTotals[currency] || { hasError: true, sum: 0 };

            let currTotal = !balances[exId][currency].hasError ? +balances[exId][currency].balance : 0;
            prefixTotals[currency].sum += currTotal;
            prefixTotals[currency].hasError = prefixTotals[currency].hasError
              ? balances[exId][currency].hasError
              : false;
          }
        });
      }
    }

    return prefixTotals;
  }

  getCardBody(currencies) {
    let { balances, inProgressCounts, inProgressAmounts, expanded } = this.state;
    const precision = 1;

    let cardBody = [];
    let finalTotals = {};

    let counter = 0;
    for (let exchangeId in balances) {
      const prefix = this.exchangePrefixesForCollaps.find((prefix) => exchangeId.startsWith(prefix.toLowerCase()));

      if (prefix) {
        if (!Array.from(cardBody).find((tr) => tr.key.startsWith('balance-' + prefix.toLowerCase()))) {
          let totals = this.getBalanceTotalByExcPrefix(currencies, balances, prefix);
          const collapsRow = (
            <tr key={'balance-' + prefix.toLowerCase() + '-' + counter}>
              <td className='balance-exchange-name' style={{ fontSize: '12px' }}>
                {prefix}
                {
                  <Button
                    type='text'
                    size='small'
                    onClick={() => this.toggleExpand(prefix)}
                    icon={expanded[prefix] ? <ArrowDownOutlined /> : <ArrowRightOutlined />}
                  />
                }
              </td>
              {currencies.map((currency) => (
                <td key={currency} style={{ fontSize: '12px' }}>
                  {!totals[currency]?.hasError ? (
                    !Number.isInteger(totals[currency]?.sum) ? (
                      totals[currency]?.sum.toFixed(currency === 'ETH' ? 2 : precision) || '0'
                    ) : (
                      totals[currency].sum
                    )
                  ) : (
                    <b>Error!</b>
                  )}
                </td>
              ))}
            </tr>
          );

          cardBody.push(collapsRow);
        }

        if (expanded[prefix]) {
          cardBody.push(this.getBalanceRow(currencies, balances[exchangeId], counter, precision));
        }
      } else {
        cardBody.push(this.getBalanceRow(currencies, balances[exchangeId], counter, precision));
      }
      counter += 1;

      currencies.forEach((currency) => {
        if (balances[exchangeId]?.[currency]) {
          finalTotals[currency] = finalTotals[currency] || 0;

          let currTotal = !balances[exchangeId][currency].hasError ? +balances[exchangeId][currency].balance : 0;
          finalTotals[currency] += currTotal;
        }
      });
    }

    cardBody.push(
      <tr key={'balance-in-progress'} className={'progress'}>
        <td>
          <i>In progress</i>
        </td>
        {currencies.map((curr) => (
          <td key={curr}>
            <i>
              {!isNaN(inProgressAmounts[curr]) && !isNaN(inProgressCounts[curr])
                ? `${+inProgressAmounts[curr].toFixed(curr === 'ETH' ? 2 : precision)}(${+inProgressCounts[curr]})`
                : `0(0)`}
            </i>
          </td>
        ))}
      </tr>
    );

    cardBody.push(
      <tr key={'balance-total'} className={'total'}>
        <td>Total</td>
        {currencies.map((curr) => (
          <td key={curr}>
            {!isNaN(finalTotals[curr])
              ? +(finalTotals[curr] + inProgressAmounts[curr]).toFixed(curr === 'ETH' ? 2 : precision)
              : 0}
          </td>
        ))}
      </tr>
    );

    return cardBody;
  }

  render() {
    let { loading, mmLoading } = this.state;
    const { exchanges } = this.props;

    return (
      <div className={'dashboard-card balances-card'}>
        <PageHeader
          className='site-page-header'
          backIcon={false}
          title='Balances'
          extra={
            <a href='#' onClick={(e) => this.reload(e, this.currencies, false)}>
              Reload
            </a>
          }
        />
        <Card className='scrollable'>
          <Skeleton
            loading={loading}
            paragraph={{ rows: this.sortExchanges(exchanges).length + 2 }}
            title={false}
            active
          >
            <table>
              <thead>
                <tr>
                  <td>Name</td>
                  {this.currencies.map((currency, index) => (
                    <td key={index}>{currency}</td>
                  ))}
                </tr>
              </thead>
              <tbody>{this.getCardBody(this.currencies)}</tbody>
            </table>
          </Skeleton>
        </Card>
        <Collapse className='collapse-px-0' ghost>
          <Panel
            className='collapse-panel-header'
            header='MM Tokens'
            key='1'
            extra={
              <a href='#' onClick={(e) => this.reload(e, this.mmCurrencies, true)}>
                Reload
              </a>
            }
          >
            <Card className='scrollable'>
              <Skeleton
                loading={mmLoading}
                paragraph={{ rows: this.sortExchanges(exchanges).length + 2 }}
                title={false}
                active
              >
                <table>
                  <thead>
                    <tr>
                      <td>Name</td>
                      {this.mmCurrencies.map((currency, index) => (
                        <td key={index}>{currency}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{this.getCardBody(this.mmCurrencies)}</tbody>
                </table>
              </Skeleton>
            </Card>
          </Panel>
        </Collapse>
      </div>
    );
  }

  shouldIgnore(exchangeId, currencyId) {
    if (currencyId === 'ETH' && exchangeId.includes('bsc')) return true;

    if ((currencyId === 'USDT' || currencyId === 'USDC') && exchangeId.includes('aur')) return true;

    if (currencyId === 'BNB' && (exchangeId.includes('eth') || exchangeId.includes('aur'))) return true;

    if (
      currencyId === 'AURORA' &&
      !exchangeId.includes('eth') &&
      !exchangeId.includes('aur') &&
      !['mexc', 'huobipro', 'kucoin', 'gateio', 'coinbase', 'bybit', 'bitget'].includes(exchangeId)
    )
      return true;

    if (currencyId === 'SQR' && !['bybit', 'gateio', 'kucoin', 'bscContract'].includes(exchangeId)) return true;

    if (
      currencyId === 'GTAI' &&
      !['bybit', 'gateio', 'kucoin', 'huobipro', 'mexc', 'bitget', 'bscContract'].includes(exchangeId)
    )
      return true;

    if (
        currencyId === 'KLAUS' &&
        !['gateio', 'mexc', 'ethContract'].includes(exchangeId)
    )
      return true;

    if (currencyId === 'SSNC' && !['mexc', 'gateio', 'ethContract'].includes(exchangeId)) return true;

    if (
      currencyId === 'BEER' &&
      !['gateio', 'kucoin', 'huobipro', 'mexc', 'bitget', 'sol_executor1'].includes(exchangeId)
    )
      return true;

    if (currencyId === 'WATER' && !['gateio', 'mexc', 'bitget', 'sol_executor1'].includes(exchangeId)) return true;
    
    if (currencyId === 'BILL' && !['mexc', 'sol_executor1'].includes(exchangeId)) return true;

    if (currencyId === 'TIME-2' && !['bitget', 'sol_executor1'].includes(exchangeId)) return true;

    if (exchangeId === 'hitbtc2') {
      return true;
    }

    return false;
  }
}

export default BalancesCard;
