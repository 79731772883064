import moment from 'moment';

export function getCsvData(rawData, ignoreFields = []) {
    let csv = [];

    if (rawData.length === 0) {
        return csv;
    }

    const data = prepareData(rawData);

    let fields = Object.keys(data[0]).filter(key => ignoreFields.indexOf(key) < 0);

    csv.push(fields);

    for (let i in data) {
        let op = data[i];
        let raw = [];

        for(let j in fields){
            let key = fields[j];
            if(typeof op[key] == "number"){
                if(['data', 'created_at', 'updated_at', 'createdAt', 'updatedAt'].indexOf(key) >= 0){
                    raw.push(moment.unix(op[key] / 1000).format("DD.MM.YYYY HH:mm:ss"));
                    continue;
                }
                
                raw.push(op[key] || op[key] !== "" ? op[key] : "0");
                continue;
            }

            raw.push(op[key]);
        }
        
        csv.push(raw)
    }

    return csv;
}

function prepareData(data) {
    const triggerTypesToIgnore = ['mevBlocker', 'mempool', 'flashbots', 'kolibrio', 'blonk', 'rpcFast', 'merkle'];
    const errorToIgnore = 'wasNotMinedInExpectedBlocks';

    data = data.filter(record => !(triggerTypesToIgnore.includes(record.triggerType) && record.errorType === errorToIgnore));

    data = data.map(task => {
        const gasUsed = Number(task?.executedExchangeOperations?.[0]?.gasUsed || 0);
        const minerTip = Number(task?.executedExchangeOperations?.[0]?.minerTip || 0);
        const borrowAmount = Number(task?.executedExchangeOperations?.[1]?.borrowAmount || 0).toFixed(20);

        const competitorGasUsed = Number(task?.taskCompetitor?.competitorGasUsed || 0);
        const competitorMinerTip = Number(task?.taskCompetitor?.competitorMinerTip || 0);

        const ourGasEfficiency = gasUsed === 0 ? 0 : parseFloat(Number(minerTip / gasUsed * Math.pow(10, 9)).toFixed(2).replace(/e-\d+/g, ''));
        const competitorGasEfficiency = competitorGasUsed === 0 ? 0 : parseFloat(Number(competitorMinerTip / competitorGasUsed * Math.pow(10, 9)).toFixed(2).replace(/e-\d+/g, ''));

        task.competitorGasEfficiency = competitorGasEfficiency;
        task.gasEfficiency = ourGasEfficiency;
        task.competitorVolume = isNaN(Number(task?.taskCompetitor?.competitorVolume)) ? 0 : parseFloat(Number(task?.taskCompetitor?.competitorVolume).toFixed(5));
        task.borrowAmount = parseFloat(borrowAmount);

        delete task?.executedExchangeOperations;
        delete task?.taskCompetitor;
        return task;
    })

    const dataGroupedByFoundBlockAndToken = data.reduce((result, task) => {
        const key = `${task.foundInBlock}-${task.token}`;
        if (!result[key]) {
            result[key] = { objects: [], bestGroupProfit: -Infinity };
        }
        result[key].objects.push(task);
        if (task.estProfit > result[key].bestGroupProfit) {
            result[key].bestGroupProfit = task.estProfit;
        }
        return result;
    }, {});

    const tasksWithMarkedBestOne = Object.values(dataGroupedByFoundBlockAndToken).flatMap((group) => {
        return group.objects.map((task) => {
            task.groupBestTask = task.estProfit === group.bestGroupProfit ? true : null;
            return task;
        });
    });

    return tasksWithMarkedBestOne;
}