import React from 'react';
import {Spin, Typography} from "antd";
import {dispose, init} from 'klinecharts'
const { Title } = Typography;

class Candles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            task: props.task,
            loading: false,
            error: false
        }
        this.chartRef = React.createRef();
    }

    async componentDidMount() {
        await this.initializeChart();
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.task !== this.props.task) {
            try {
                await this.disposeChart();
                await this.initializeChart();
            } catch (error) {
                console.error('Error initializing chart:', error);
            }
        }
    }

    async disposeChart() {
        if (this.chartRef.current) {
            dispose('k-line-chart');
            this.chartRef.current = null;
        }
    }

    getCandles = async (task) => {
        this.setState({ loading: true, error: false });
        const cexOperation = task.exchangeOperations[1];
        const firstCexMo = cexOperation.marketOperations[0];
        const {exchangeId} = cexOperation;
        const symbol = firstCexMo.symbol;
        const timestamp = Date.parse(task.timestamp) - 60 * 5 * 1000
        try {
            const candlesResponse = await fetch(`/api/tasks/candles?symbol=${symbol}&exchangeId=${exchangeId}&timestamp=${timestamp}`);
            if (candlesResponse.status !== 200) {
                this.setState({ loading: false, error: true });
                return null;
            }
            const candlesData = await candlesResponse.json();
            this.setState({ loading: false, error: false });
            return candlesData?.nativeCandles?.map(x => {
                return {
                    timestamp: x[0],
                    open: x[1],
                    high: x[2],
                    low: x[3],
                    close: x[4],
                    volume: x[5]
                }
            });
        }
        catch (e) {
            console.log(e);
            this.setState({ loading: false, error: true });
        }
    };

    async initializeChart () {
        const chart = init('k-line-chart' + this.props.task?.id);
        const candles = await this.getCandles(this.props.task);
        if (!candles) {
            return;
        }
        chart.setPriceVolumePrecision(this.getTaskPricePrecision(candles),2);
        chart?.applyNewData(candles);
        this.chartRef.current = chart;
    }

    getTaskPricePrecision = (candles) => {
        return candles?.reduce((max, obj) => {
            const highStr = obj.high.toString();
            const lowStr = obj.low.toString();

            const highDecimalPart = highStr.split('.')[1] || '';
            const lowDecimalPart = lowStr.split('.')[1] || '';

            const highDigits = highDecimalPart.length;
            const lowDigits = lowDecimalPart.length;

            const maxObjDigits = Math.max(highDigits, lowDigits);
            return Math.max(max, maxObjDigits);
        }, 0);
    }

    render() {
        let {loading, error} = this.state;
        return (
            <div className={"task-card-section-title"}>
                <Title level={5}>Candles</Title>
                <Spin spinning={loading}>
                {
                    error ? <span style={{color: 'red'}}>Candles graph is currently unavailable </span> :
                    <div id={'k-line-chart' + this.props.task?.id} style={{ width: '100%', height: '400px' }}></div>
                }
                </Spin>
            </div>
        )
    }
}

export default Candles;
