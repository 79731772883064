import moment from 'moment';

const dateFormat = "DD.MM.YYYY HH:mm";

export function taskHandler(savedOperations, operations) {
    const data = [];

    for (const task of operations) {
        if (!task.exchangeFrom || !task.exchangeTo) {
            continue;
        }

        if (savedOperations.some(item => item.taskId === task.taskId)) {
            continue;
        }
        task.key = task.id;
        data.push(task);
    }

    return savedOperations.concat(data);
}

export function getDateFormat() { return dateFormat }
