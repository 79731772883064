import React from 'react';
import moment from 'moment';
import axios from "axios";
import { Button, Input, message, Space, Table, Tag } from "antd";
import Highlighter from 'react-highlight-words';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { EditOutlined, SearchOutlined, ShareAltOutlined, CaretRightFilled, CaretLeftFilled } from '@ant-design/icons';
import "./TaskList.less";

import TaskCard from './components/TaskCard';
import EditTaskModal from './components/TaskCard/components/Info/components/EditTaskModal';
import { getBlockChainExplorerLink, getChainUrlQueryByType } from "../../utils/utils";
import BlockChainUrlTypes from "../../constants/blockChainUrlTypes";
import { DEX_EXCHANGES } from '../../constants/exchanges';
import { arraysEqual } from '../../utils/arrayUtil';

const { Search } = Input;
const dateFormat = "DD.MM.YYYY HH:mm:ss";

class TaskList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchedColumn: null,
      showFilter: props.hasOwnProperty("showFilter") ? props.showFilter : true,
      showEditButton: props.hasOwnProperty("showEditButton") ? props.showEditButton : false,
      showTaskCardButton: props.hasOwnProperty("showTaskCardButton") ? props.showTaskCardButton : true,
      showSearchInput: props.hasOwnProperty("showSearchInput") ? props.showSearchInput : false,
      hideColumns: props.hasOwnProperty("hideColumns") ? props.hideColumns : [],
      editTask: null,
      searching: false,
      filteredIds: false,
      windowWidth: undefined,
      loading: false,
      blockColumnVisible: false,
      compInfoColumnVisible: false
    }

    this.edit = this.edit.bind(this);
    this.editClose = this.editClose.bind(this);
    this.editCallback = this.editCallback.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.changeBlockColumnViasbility = this.changeBlockColumnViasbility.bind(this);
    this.changeCompInfoColumnViasbility = this.changeCompInfoColumnViasbility.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => this.setState({
    windowWidth: window.innerWidth
  });

  componentDidUpdate(prevProps) {
    if (this.props.loading !== prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  getColumnSearchProps(dataIndex) {
    if (!this.state.showFilter) {
      return {}
    }

    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                this.setState({
                  searchText: selectedKeys[0],
                  searchedColumn: dataIndex,
                });
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select(), 100);
        }
      },
      render: text =>
        this.state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    }
  };

  handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset(clearFilters) {
    clearFilters();
    this.setState({ searchText: '' });
  };

  edit(e, id) {
    e.preventDefault();
    this.setState({
      editTask: id
    })
  }

  editClose() {
    this.setState({
      editTask: null
    })
  }

  changeBlockColumnViasbility() {
    this.setState({ blockColumnVisible: !this.state.blockColumnVisible })
  }

  changeCompInfoColumnViasbility() {
    this.setState({ compInfoColumnVisible: !this.state.compInfoColumnVisible })
  }

  editCallback() {
    this.props.reload();
  }

  async onSearch(value) {
    if (this.state.searching) {
      return;
    }

    if (!value || value === "") {
      this.setState({ filteredIds: false });
      return;
    }

    this.setState({ searching: true });

    try {
      let response = await axios(`/api/tasks/search?search=${value}`);
      if (response.status !== 200) {
        throw new Error(response.message || "Internal server error");
      }

      this.setState({
        filteredIds: response.data.ids
      })
    } catch (e) {
      message.error(e.message, 8);
    }

    this.setState({ searching: false });
  }

  customSorter(a, b, sortOrder) {
    const numA = parseFloat(a);
    const numB = parseFloat(b);
    
    if (isNaN(numA) && isNaN(numB)) {
      return 0;
    } 
    if (sortOrder === 'ascend') {
      if (isNaN(numA)) {
        return 1;
      } else if (isNaN(numB)) {
        return -1;
      }
    } else {
      if (isNaN(numA)) {
        return -1;
      } else if (isNaN(numB)) {
        return 1;
      }
    }

    return numA - numB;
  }

  render() {
    let { showFilter, hideColumns, showTaskCardButton, showEditButton, showSearchInput, editTask, searching, filteredIds, windowWidth, loading } = this.state;
    let { operations } = this.props;
    const exchanges = [];
    const statuses = [];
    const triggerTypes = [];
    const miners = [];
    const errorTypes = [];
    const baseCurrencies = [];
    const strategies = [];
    const defiProtocols = {};
    const compAddresses = []

    let tasks = filteredIds !== false ? operations.filter(op => filteredIds.includes(op.key)) : operations;
    if (showFilter) {
      for (let i in tasks) {
        let task = operations[i];
        if (statuses.indexOf(task.status) < 0) {
          statuses.push(task.status);
        }

        if (errorTypes.indexOf(task.errorType) < 0) {
          errorTypes.push(task.errorType);
        }

        if (triggerTypes.indexOf(task.triggerType || "no data") < 0) {
          triggerTypes.push(task.triggerType || "no data");
        }

        if (!!task.miner && miners.indexOf(task.miner) < 0) {
          miners.push(task.miner);
        }

        if (exchanges.indexOf(task.exchangeFrom) < 0) {
          exchanges.push(task.exchangeFrom);
        }

        if (exchanges.indexOf(task.exchangeTo) < 0) {
          exchanges.push(task.exchangeTo);
        }

        if(baseCurrencies.indexOf(task.baseCurrency) < 0) {
          baseCurrencies.push(task.baseCurrency);
        }

        if(strategies.indexOf(task.strategy) < 0 && task.strategy !== '') {
          strategies.push(task.strategy);
        }
        
        if (DEX_EXCHANGES.includes(task.exchangeFrom)) {
          let defis = task.typesFrom.sort();

          if(defiProtocols.hasOwnProperty(task.exchangeFrom)) {
            if (!defiProtocols[task.exchangeFrom].some(arr => arraysEqual(arr, defis))) {
              defiProtocols[task.exchangeFrom].push(defis);
            }
          } else {
            defiProtocols[task.exchangeFrom] = [defis];
          }
        }

        if (task.taskCompetitor && compAddresses.indexOf(task.taskCompetitor.competitorAddressFrom) < 0) {
          compAddresses.push(task.taskCompetitor.competitorAddressFrom);
        }
      }
    }

    const renderPoolType = (value, recordTypes) => {
      return <>
        {
            !!recordTypes && DEX_EXCHANGES.includes(value) &&
            <span className='mo-types'>
        {
          recordTypes.map((type, i) => {
            let shortType;
            if (value === 'sol') {
              const match = type.match(/^(\w)[a-zA-Z]*(\d+)$/);
              shortType = match ? `${match[1]}${match[2]}` : type;
            } else {
              shortType = type[0] + type.at(-1);
            }
            return (
                <span key={i}>
                {shortType}
                  {i < recordTypes.length - 1 && '+'}
              </span>
            );
          })
        }
      </span>
        }
      </>
    };

    const columns = [
      {
        title: 'ID',
        dataIndex: 'taskId',
        key: 'taskId',
        width: "70px",
        fixed: windowWidth > 800 ? 'left' : false,
        ...this.getColumnSearchProps('taskId'),
        render: id => (
          <CopyToClipboard text={id} onCopy={() => {
            message.success('Copied!');
          }}>
            <a href="#" onClick={(e) => e.preventDefault()}>{id.substr(0, 6)}</a>
          </CopyToClipboard>
        )
      },
      {
        title: 'Token',
        dataIndex: 'tokenSymbol',
        key: 'tokenSymbol',
        width: "70px",
        fixed: windowWidth > 800 ? 'left' : false,
        ...this.getColumnSearchProps('tokenSymbol'),
      },
      {
        title: '',
        dataIndex: 'isFromMultihop',
        key: 'isFromMultihop',
        width: "20px",
        filters: !!showFilter ? [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ] : undefined,
        onFilter: (value, record) => value === !!record.isFromMultihop,
        render: (isFromMultihop) => (
          <>{isFromMultihop ? <ShareAltOutlined /> : ""}</>
        ),
      },
      {
        title: 'From',
        dataIndex: 'exchangeFrom',
        key: 'exchangeFrom',
        width: "70px",
        filters: !!showFilter
          ? exchanges.map(ex => {
            return {
              text: ex,
              value: `${ex}${defiProtocols.hasOwnProperty(ex) ? '-submenu' : ''}`,
              children: defiProtocols.hasOwnProperty(ex)
                ? [
                  {
                    text: 'All',
                    value: ex,
                  },
                  ...defiProtocols[ex]
                    .map(protocols => ({ text: renderPoolType(ex, protocols), value: protocols }))
                    .sort((a, b) => a.value.length - b.value.length),
                ]
                : undefined
            }
          })
          : undefined,
        onFilter: (value, record) => {
          if (exchanges.includes(value)) {
            return record.exchangeFrom === value;
          }

          return arraysEqual(record.typesFrom, value);
        },
        render: (value, record) => {
          return <>
            {value}
            {
              renderPoolType(value, record.typesFrom)
            }
          </>
        },
      },
      {
        title: 'To',
        dataIndex: 'exchangeTo',
        key: 'exchangeTo',
        width: "70px",
        filters: !!showFilter ? exchanges.map(ex => { return { text: ex, value: ex } }) : undefined,
        onFilter: (value, record) => record.exchangeTo.indexOf(value) === 0,
        render: (value, record) => {
          return <>
            {value}
            {
              renderPoolType(value, record.typesTo)
            }
          </>
        },
      },
      {
        title: '',
        dataIndex: 'isToMultihop',
        key: 'isToMultihop',
        width: "20px",
        filters: !!showFilter ? [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ] : undefined,
        onFilter: (value, record) => value === !!record.isToMultihop,
        render: (isToMultihop) => (
          <>{isToMultihop ? <ShareAltOutlined /> : ""}</>
        ),
      },
      {
        title: 'Strategy',
        dataIndex: 'strategy',
        key: 'strategy',
        width: "70px",
        filters: !!showFilter ? strategies.map(st => { return { text: st, value: st } }) : undefined,
        onFilter: (value, record) => record.strategy.indexOf(value) === 0,
      },
      {
        title: 'Trigger type',
        dataIndex: 'triggerType',
        key: 'triggerType',
        width: "150px",
        filters: !!showFilter ? triggerTypes.map(tt => { return { text: tt, value: tt } }) : undefined,
        onFilter: (value, record) => record.triggerType.indexOf(value) === 0,
      },
      {
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Block Info
            <Button 
              type="text" 
              size="small" 
              style={{ marginLeft: 2 }}
              onClick={this.changeBlockColumnViasbility}
              icon={<CaretRightFilled style={{ color: '#BFBFBF' }} />} 
            />
          </div>
        ),
        dataIndex: 'blockInfo',
        key: 'blockInfo',
        width: "60px",
        hidden: this.state.blockColumnVisible
      },
      {
        title: 'Found in block',
        dataIndex: 'foundInBlock',
        key: 'foundInBlock',
        width: "90px",
        filters: !!showFilter ? [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ] : undefined,
        hidden: !this.state.blockColumnVisible,
        onFilter: (value, record) => value === !!record.foundInBlock,
      },
      {
        title: 'Mining block',
        dataIndex: 'miningBlock',
        key: 'miningBlock',
        width: "105px",
        filters: !!showFilter ? [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ] : undefined,
        hidden: !this.state.blockColumnVisible,
        onFilter: (value, record) => value === !!record.miningBlock,
        render: (miningBlock, record) => {
          return (
            <>
              <a
                href={`${getBlockChainExplorerLink(record.exchangeFrom)}${getChainUrlQueryByType(
                  record.exchangeFrom,
                  BlockChainUrlTypes.Block,
                  miningBlock
                )}`}
                target='_blank'
              >
                {miningBlock}
              </a>{' '}
              {!!record.foundInBlock && !!miningBlock ? <i>({miningBlock - record.foundInBlock})</i> : ''}
            </>
          );
        },
      },
      {
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Miner
            <Button 
              type="text" 
              size="small" 
              style={{ marginLeft: 2 }}
              onClick={this.changeBlockColumnViasbility}
              icon={<CaretLeftFilled style={{ color: '#BFBFBF' }} />} 
            />
          </div>
        ),
        dataIndex: 'miner',
        key: 'miner',
        width: "70px",
        filters: !!showFilter ? miners.map(tt => { return { text: tt, value: tt } }) : undefined,
        hidden: !this.state.blockColumnVisible,
        onFilter: (value, record) => record.miner === value,
        render: (miner, record) => {
          return (miner?.length || 0) < 40 ? (
            <>{miner}</>
          ) : (
            <a
              href={`${getBlockChainExplorerLink(record.exchangeFrom)}${getChainUrlQueryByType(
                record.exchangeFrom,
                BlockChainUrlTypes.Address,
                miner
              )}`}
              target='_blank'
            >
              {miner.substr(0, 2) + '...' + miner.substr(-3)}
            </a>
          );
        },
      },
      {
        title: 'Base',
        dataIndex: 'baseCurrency',
        key: 'baseCurrency',
        width: "45px",
        align: "center",
        filters: !!showFilter ? baseCurrencies.map(bc => { return { text: bc, value: bc } }) : undefined,
        render: (value) => <>{value}</>,
        onFilter: (value, record) => record.baseCurrency.indexOf(value) === 0,
      },
      {
        title: '',
        dataIndex: 'baseCurrency',
        key: 'baseCurrency',
        width: "30px",
        align: "left",
        render: (value) => {
          const coinSource = `/public/imgs/${value.toLowerCase()}.svg`;
          return (
            <>
              <img src={coinSource} alt={value} style={{ width: '14px', height: '14px' }}/>
            </>
          );
        },
      },
      {
        title: 'Vol.',
        dataIndex: 'volume',
        key: 'volume',
        align: "right",
        width: "40px",
        sorter: !!showFilter ? (a, b) => a.volume - b.volume : undefined,
      },
      {
        title: 'Est. result',
        dataIndex: 'estResult',
        key: 'estResult',
        align: "right",
        width: "90px",
        render: (value) => <>{+value.toFixed(6)}</>,
        sorter: !!showFilter ? (a, b) => a.estResult - b.estResult : undefined,
      },
      {
        title: 'Est. profit',
        dataIndex: 'estProfit',
        key: 'estProfit',
        align: "right",
        width: "90px",
        render: (value) => <>{+value.toFixed(4)}</>,
        sorter: !!showFilter ? (a, b) => a.estProfit - b.estProfit : undefined,
      },
      {
        title: 'Real profit',
        dataIndex: 'realProfit',
        key: 'realProfit',
        align: "right",
        width: "80px",
        sorter: !!showFilter ? (a, b) => a.realProfit - b.realProfit : undefined,
        render: profit => {
          let color = 'inherit';
          if (profit && profit < 0) {
            color = 'red'
          } else if (profit && profit > 0) {
            color = 'green'
          }
          return <span style={{ color: color }}>{+profit.toFixed(4)}</span>
        },
      },
      {
        title: 'Miner tip',
        dataIndex: 'minerTip',
        key: 'minerTip',
        align: "right",
        width: "80px",
        sorter: !!showFilter ? (a, b) => a.minerTip - b.minerTip : undefined,
        render: (minerTip) => Number(minerTip).toFixed(5)
      },
      {
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Comp. info
            <Button 
              type="text" 
              size="small" 
              style={{ marginLeft: 2 }}
              onClick={this.changeCompInfoColumnViasbility}
              icon={<CaretRightFilled style={{ color: '#BFBFBF' }} />} 
            />
          </div>
        ),
        dataIndex: 'compInfo',
        key: 'compInfo',
        width: "60px",
        hidden: this.state.compInfoColumnVisible
      },
      {
        title: 'Comp. vol.',
        dataIndex: 'competitorVolume',
        key: 'competitorVolume',
        align: "right",
        width: "75px",
        hidden: !this.state.compInfoColumnVisible,
        sorter: !!showFilter ? (a, b, sortOrder) => 
          this.customSorter(a.taskCompetitor?.competitorVolume, b.taskCompetitor?.competitorVolume, sortOrder) : undefined,
        render: (_, record) => {
          let compVol = record.taskCompetitor?.competitorVolume;

          if (compVol)
            return Number(compVol).toFixed(3)
        }
      },
      {
        title: 'Comp. Miner tip',
        dataIndex: 'competitorMinerTip',
        key: 'competitorMinerTip',
        align: "right",
        width: "75px",
        hidden: !this.state.compInfoColumnVisible,
        sorter: !!showFilter ? (a, b, sortOrder) => 
          this.customSorter(a.taskCompetitor?.competitorMinerTip, b.taskCompetitor?.competitorMinerTip, sortOrder) : undefined,
        render: (_, record) => {
          let compMinerTip = record.taskCompetitor?.competitorMinerTip;

          if (compMinerTip)
            return Number(compMinerTip).toFixed(3)
        }
      },
      {
        title: (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            Comp. address
            <Button 
              type="text" 
              size="small" 
              style={{ marginLeft: 2 }}
              onClick={this.changeCompInfoColumnViasbility}
              icon={<CaretLeftFilled style={{ color: '#BFBFBF' }} />} 
            />
          </div>
        ),
        dataIndex: 'competitorAddressFrom',
        key: 'competitorAddressFrom',
        width: "80px",
        hidden: !this.state.compInfoColumnVisible,
        filters: !!showFilter ? compAddresses.map(ca => { return { text: ca, value: ca } }) : undefined,
        onFilter: (value, record) => record.taskCompetitor?.competitorAddressFrom === value,
        filterSearch: true,
        render: (_, record) => { 
          let compAddress = record.taskCompetitor?.competitorAddressFrom;

          if (compAddress) {
            return (
              <a
                href={`${getBlockChainExplorerLink(record.exchangeFrom)}${getChainUrlQueryByType(
                  record.exchangeFrom,
                  BlockChainUrlTypes.Address,
                  compAddress
                )}`}
                target='_blank'
              >
                {compAddress.substr(0, 3) + '...' + compAddress.substr(-3)}
              </a>
            );
          }
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: "center",
        width: "140px",
        filters: !!showFilter ? statuses.map(status => { return { text: status, value: status } }) : undefined,
        onFilter: (value, record) => record.status.indexOf(value) === 0,
        render: (status, record) => (
          <>
            <Tag color={
              status === 'success'
                ? "green"
                : status === "error"
                  ? "red"
                  : status === "terminated"
                    ? "orange"
                    : "blue"
            }>
              {status}
            </Tag>
            {
              record.hasManualProcessing &&
              <Tag color="default">edited</Tag>
            }
          </>
        ),
      },
      {
        title: 'Error type',
        dataIndex: 'errorType',
        key: 'errorType',
        filterMode: 'tree',
        filters: !!showFilter ? errorTypes.sort((a, b) => a > b ? 1 : 0).map(type => { return { text: type || '-', value: type } }) : undefined,
        onFilter: (value, record) => value === record.errorType,
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: "130px",
        fixed: windowWidth > 800 ? 'right' : false,
        render: (date) => <>{moment(date).format(dateFormat)}</>,
        sorter: !!showFilter ? (a, b) => (new Date(a.date)).getTime() - (new Date(b.date)).getTime() : undefined,
      },
    ].filter(item => !item.hidden);;

    if (showEditButton) {
      columns.push({
        title: '',
        dataIndex: 'edit',
        key: "edit",
        align: "right",
        width: "25px",
        fixed: windowWidth > 800 ? 'right' : false,
        render: (data, row) => (
          <Button type="danger" size={"small"} onClick={(e) => this.edit(e, row.key)}>
            <EditOutlined />
          </Button>
        )
      })
    }

    return (
      <>
        {
          showSearchInput &&
          <Search
            key={"search-input"}
            placeholder="Search by tx hash"
            onSearch={this.onSearch}
            enterButton
            disabled={loading}
            loading={searching}
            style={
              {
                width: "496px",
                marginTop: "-20px",
                marginBottom: "10px",
                float: "right"
              }
            }
          />
        }
        <Table
          key={"tasks-table"}
          className={"tasks-table"}
          columns={columns.filter(col => hideColumns.indexOf(col.dataIndex) < 0)}
          dataSource={tasks}
          size="small"
          pagination={{ defaultPageSize: 25 }}
          loading={loading}
          rowClassName={(row) => 'task-' + row.id}
          scroll={{ x: "max-content" }}
          expandable={
            !!showTaskCardButton
              ? {
                expandedRowRender: record => <TaskCard id={record.key} user={this.props.user} />,
                rowExpandable: record => !!record,
              }
              : undefined
          }
        />

        {
          !!showEditButton && !!editTask &&
          <EditTaskModal
            key={"edit-tasks-modal"}
            taskId={editTask}
            visible={!!editTask}
            onClose={this.editClose}
            user={this.props.user}
            callback={this.editCallback}
          />
        }
      </>

    )
  }
}

export default TaskList;
