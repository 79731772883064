export const GraphOptions = [
    // {
    //     name: 'Test',
    //     option: 'test',
    //     filters: []
    // },
    // {
    //     name: 'Tasks count',
    //     option: 'tasksCount',
    //     filters:
    //         [
    //             {
    //                 name: 'Trigger Type',
    //                 key: 'triggerType',
    //                 values: ['mempool', 'cexMarketUpdate'],
    //                 multiple: true,
    //                 required: true,
    //                 allowCustom: true,
    //             },
    //             {
    //                 name: 'Status',
    //                 key: 'status',
    //                 values: ['success', 'error'],
    //                 multiple: true,
    //                 required: false,
    //             },
    //             {
    //                 name: 'CEX',
    //                 key: 'cex',
    //                 values: ['Kukoin', 'Binance'],
    //                 multiple: false,
    //                 required: false,
    //             },
    //             {
    //                 name: 'DEX',
    //                 key: 'dexs',
    //                 values: ['uniswap', 'sushiswap'],
    //                 multiple: true,
    //                 required: false,
    //                 allowCustom: true,
    //             }
    //         ]
    // },


    // {
    //     name: 'Competitor`s arb transactions count',
    //     option: 'getCompetitorTxsCount',
    //     filters:
    //         [
    //             {
    //                 name: 'Competitor Address',
    //                 key: 'address',
    //                 values: [],
    //                 multiple: false,
    //                 required: true,
    //                 allowCustom: true
    //             }
    //         ]
    // },
    // {
    //     name: 'Competitor`s fee amount',
    //     option: 'getCompetitorFee',
    //     filters:
    //         [
    //             {
    //                 name: 'Competitor Address',
    //                 key: 'address',
    //                 values: [],
    //                 multiple: false,
    //                 required: true,
    //                 allowCustom: true
    //             }
    //         ]
    // },
    
    
    {
        name: 'Contract daily fee ETH',
        option: 'contractDailyFee',
        filters:
            [
                {
                    name: 'Contract Address',
                    key: 'address',
                    values: [],
                    multiple: false,
                    required: true,
                    allowCustom: true
                }
            ]
    }
]
