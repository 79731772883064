import React from 'react';
import { Table, Tag, Typography } from "antd";
import "./ManualProcessing.less";

const { Title } = Typography;

const columns = [
    {
        title: 'Editor',
        dataIndex: 'editor',
        key: 'editor',
        width: '200px'
    },
    {
        title: 'Manual processing result',
        dataIndex: 'volume',
        key: 'volume',
        width: '200px'
    },
    {
        title: 'New status',
        dataIndex: 'status',
        key: 'status',
        width: '150px',
        render: (status, record) => (
            <Tag color={status === 'success' ? "green" : status === "error" ? "red" : "blue"}> {status} </Tag>
        )
    },
    {
        title: 'Comment',
        dataIndex: 'comment',
        key: 'comment'
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: '150px',
        render: date => (
            <span>{new Date(date).toLocaleDateString() + " " + new Date(date).toLocaleTimeString()}</span>
        )
    },
]

class ManualProcessing extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { manualProcessing } = this.props;

        const data = [];
        for(let i in manualProcessing){
            let process = manualProcessing[i];
            data.push({
                key: Number(i)+1,
                editor: process.editor,
                volume: process.result,
                status: process.status,
                comment: process.comment,
                date: new Date(process.createdAt).getTime(),
            })
        }

        return (
            <div className={"manual-processing"}>
                <div className={"task-card-section-title"}>
                    <Title level={5}>Manual processing</Title>
                </div>
                <Table size="small" pagination={false} columns={columns} dataSource={data} />
            </div>
        )
    }
}

export default ManualProcessing;