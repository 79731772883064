import {Menu, Modal, Select} from "antd";
import React from "react";
import {GraphOptions} from "../graphOptions";
import './CustomGraphOptionsModal.less'

const { Option } = Select;
const { SubMenu } = Menu;

class CustomGraphOptionsModal extends React.Component {
    graphOptions = GraphOptions;
    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selectedOptions: {},
        };
        this.handleNewGraphName = this.handleNewGraphName.bind(this);
        this.handleFilters = this.handleFilters.bind(this);
        this.handleOption = this.handleOption.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    handleNewGraphName(value) {
        const graph = this.graphOptions.find(option => option.option === value);
        if (!graph) {
            return;
        }
        this.setState({
            graph,
            options: graph?.filters.map(filter => ({...filter, checked: !!filter?.required})),
            selectedOptions: {},
        })
    }

    handleOption(option, value) {
        this.setState({
            selectedOptions: {...this.state.selectedOptions, [option?.key]: value}
        });
    }

    handleFilters(filter) {
        this.setState({
            options: this.state.options?.map(option => ({...option, checked: option.key === filter.key ? !option.checked : option.checked}))
        });
    }

    onSubmit() {
        this.props.callback({name: this.state.graph?.name, key: this.state.graph?.option, filters: this.state.selectedOptions});
        this.props.onClose();
    }

    render() {
        const { visible, onClose } = this.props;
        const {options} = this.state;

        return (
            <Modal
                className={"modal"}
                title="Create new graph"
                visible={!!visible}
                onCancel={onClose}
                onOk={this.onSubmit}
                centered={true}
                okButtonProps={{ form: 'task-edit-modal-form', key: 'submit', htmlType: 'submit' }}
                destroyOnClose
            >
                <div className={'filters-container'}>
                    <div className={'filters-container-item'}>
                        <div>Select graph type</div>
                        <Select
                            key={"graph-type-name"}
                            className={'filter-by-triggertype'}
                            allowClear
                            placeholder="Graph"
                            defaultValue={[]}
                            onChange={this.handleNewGraphName}
                            maxTagCount={'responsive'}>
                            {
                                this.graphOptions.map(option=> <Option key={'option' + option.option} value={option.option}>{option.name}</Option>)
                            }
                        </Select>
                    </div>
                    {
                        options?.filter(option => !!option.checked)?.map(option =>
                            <div className={'filters-container-item'}>
                                <div>{option.name}</div>
                                <Select
                                    key={"graph-options" + option.key}
                                    className={'filter-by-triggertype'}
                                    mode={option?.allowCustom ? 'tags' : option.multiple ? 'multiple' : ''}
                                    allowClear
                                    placeholder={'Select '+ option.name}
                                    defaultValue={[]}
                                    onChange={e => this.handleOption(option, e)}
                                    maxTagCount={'responsive'}>
                                    {
                                        option.values?.map(f => <Option key={'filter-value' + f} value={f}>{f}</Option>)
                                    }
                                </Select>
                            </div>
                        )
                    }
                    {
                        !!options.length &&
                        <div className={'filters-container-item'}>
                            <Menu  mode="horizontal" >
                                <SubMenu
                                    title="+ Filters"
                                    key="filters-menu-item">
                                    {
                                        options?.map(filter =>
                                            <Menu.Item key={'child-menu-item-' + filter.name} disabled={filter.required} onClick={_ => this.handleFilters(filter)}>
                                                <div className={'menu-filter-option'}>
                                                    {filter.name}
                                                    {filter.checked && <img src="/public/imgs/checkmark.svg" alt={''} />}
                                                </div>
                                            </Menu.Item>
                                        )
                                    }
                                </SubMenu>
                            </Menu>
                        </div>
                    }

                </div>
            </Modal>
        )
    }
}

export default CustomGraphOptionsModal;
