import React from 'react';
import { PageHeader, Badge, Button, Typography, Table, Alert, Switch, Space, Input, Drawer, List, message } from "antd";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import "./Exchanges.less";

import AddExchangeDrawer from './components/AddExchangeDrawer';

const { Title } = Typography;

class Exchanges extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            forUpdate: [],
            loading: false,
            error: false,
            visibleUpdateDrawer: false,
            visibleAddDrawer: false
        }

        this.onInputChange = this.onInputChange.bind(this)
        this.onSwitchChange = this.onSwitchChange.bind(this)
        this.onUpdateDrawerShow = this.onUpdateDrawerShow.bind(this)
        this.onUpdateDrawerClose = this.onUpdateDrawerClose.bind(this)
        this.onClearUpdates = this.onClearUpdates.bind(this)
        this.onUpdate = this.onUpdate.bind(this)
        this.deleteOneUpdate = this.deleteOneUpdate.bind(this)
        this.onAddDrawerShow = this.onAddDrawerShow.bind(this)
        this.onAddDrawerClose = this.onAddDrawerClose.bind(this)
    }

    getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                confirm({ closeDropdown: false });
                                this.setState({
                                    searchText: selectedKeys[0],
                                    searchedColumn: dataIndex,
                                });
                            }}
                        >
                            Filter
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (value, record) =>
                record[dataIndex]
                    ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                    : '',
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            },
            render: text =>
                this.state.searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[this.state.searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                        text
                    ),
        }
    };

    handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset(clearFilters) {
        clearFilters();
        this.setState({ searchText: '' });
    };

    onInputChange(e, field, record) {
        let { forUpdate } = this.state;
        let value = e.target.value;
        if (value == "") {
            value = null;
        }

        let updateIndex;
        for (let i in forUpdate) {
            if (forUpdate[i].exchangeId === record.exchangeId) {
                updateIndex = i;
            }
        }
        if (!updateIndex) {
            forUpdate.push({
                exchangeId: record.exchangeId,
                data: {
                    [field]: value
                }
            })
        } else {
            if (value == record[field]) {
                delete forUpdate[updateIndex].data[field];
            } else {
                forUpdate[updateIndex].data[field] = value;
            }
        }

        this.setState({
            forUpdate: this.clearEmptyUpdates(forUpdate)
        })
    }

    onSwitchChange(value, field, record) {
        let { forUpdate } = this.state;

        let updateIndex;
        for (let i in forUpdate) {
            if (forUpdate[i].exchangeId === record.exchangeId) {
                updateIndex = i;
            }
        }

        if (!updateIndex) {
            forUpdate.push({
                exchangeId: record.exchangeId,
                data: {
                    [field]: value ? 1 : 0
                }
            })
        } else {
            if (!forUpdate[updateIndex].data.hasOwnProperty(field)) {
                forUpdate[updateIndex].data[field] = value ? 1 : 0;
            } else {
                if (forUpdate[updateIndex].data[field] !== (value ? 1 : 0)) {
                    delete forUpdate[updateIndex].data[field];
                }
            }
        }

        this.setState({
            forUpdate: this.clearEmptyUpdates(forUpdate)
        })
    }

    clearEmptyUpdates(updates) {
        return updates.filter(update => Object.keys(update.data).length > 0);
    }

    deleteOneUpdate(exchangeId) {
        let { forUpdate } = this.state;
        let data = forUpdate.filter((row) => !(row.exchangeId === exchangeId))
        this.setState({
            forUpdate: data
        })
    }

    onUpdateDrawerShow() {
        this.setState({
            visibleUpdateDrawer: true
        })
    }

    onUpdateDrawerClose() {
        this.setState({
            visibleUpdateDrawer: false
        })
    }

    onClearUpdates() {
        this.setState({
            forUpdate: []
        })
        this.props.parentUpdate();
        this.onUpdateDrawerClose();
    }

    async onUpdate() {
        let { forUpdate } = this.state;
        if (this.state.loading === true || !forUpdate || forUpdate.length === 0) {
            return false;
        }

        this.setState({ loading: true, error: false });

        try {
            let update = await fetch('/api/console/exchanges', {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    "x-access-token": this.props.user['accessToken']
                },
                body: JSON.stringify({
                    updates: forUpdate
                })
            });
            if (update.status !== 200) {
                update = await update.json();
                this.setState({ error: update.message });
            } else {
                message.success('Updated');
                this.onUpdateDrawerClose();
                this.onClearUpdates();
                await this.setState({ loading: false });
                await this.props.parentUpdate();
            }
        } catch (e) {
            this.setState({ error: e.message });
        }
        this.setState({ loading: false });
    }

    onAddDrawerShow() {
        this.setState({
            visibleAddDrawer: true
        })
    }

    onAddDrawerClose() {
        this.setState({
            visibleAddDrawer: false
        })
    }

    render() {
        let { error, loading, forUpdate, visibleUpdateDrawer, visibleAddDrawer } = this.state;
        const { exchanges } = this.props;

        const data = [];
        for (let i in exchanges) {
            let exchange = exchanges[i];

            data.push({
                key: exchange.id,
                exchangeId: exchange.exchangeId,
                exchangeName: exchange.exchangeName,
                isBuyingEnabled: exchange.isBuyingEnabled,
                isSellingEnabled: exchange.isSellingEnabled,
                minEthVolume: exchange.minEthVolume,
                normalEthVolume: exchange.normalEthVolume,
            })
        }

        const isSwitchChacked = (field, value, record) => {
            let checked = !!value;
            let update = forUpdate.find((row) => row.exchangeId === record.exchangeId && row.data.hasOwnProperty(field));
            if (update) {
                checked = !!update.data[field];
            }
            return checked
        }

        const changedInputField = (field, value, record) => {
            let checked = value;
            let update = forUpdate.find((row) => row.exchangeId === record.exchangeId && row.data.hasOwnProperty(field));
            if (update) {
                checked = update.data[field];
            }
            return checked
        }

        const columns = [
            {
                title: 'ID',
                dataIndex: 'exchangeId',
                ...this.getColumnSearchProps('exchangeId')
            },
            {
                title: 'Name',
                dataIndex: 'exchangeName',
                ...this.getColumnSearchProps('exchangeName'),
                render: (value, record) => {
                    return <Input type={'text'} required={true} style={{ width: '100%' }} value={changedInputField('exchangeName', value, record)} onChange={(e) => this.onInputChange(e, 'exchangeName', record)} />
                }
            },
            {
                title: 'Buy',
                dataIndex: 'isBuyingEnabled',
                filters: [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }],
                onFilter: (value, record) => record.isBuyingEnabled === value,
                render: (value, record) => {
                    return <Switch size="small" checked={isSwitchChacked('isBuyingEnabled', value, record)} onChange={(e) => this.onSwitchChange(e, 'isBuyingEnabled', record)} />
                }
            },
            {
                title: 'Sell',
                dataIndex: 'isSellingEnabled',
                filters: [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }],
                onFilter: (value, record) => record.isSellingEnabled === value,
                render: (value, record) => {
                    return <Switch size="small" checked={isSwitchChacked('isSellingEnabled', value, record)} onChange={(e) => this.onSwitchChange(e, 'isSellingEnabled', record)} />
                }
            },
            {
                title: 'Min. ETH Volume',
                dataIndex: 'minEthVolume',
                width: "200px",
                render: (value, record) => {
                    return <Input type={'number'} required={true} min={0} style={{ width: '100%' }} value={changedInputField('minEthVolume', value, record)} onChange={(e) => this.onInputChange(e, 'minEthVolume', record)} />
                }
            },
            {
                title: 'Normal ETH Volume',
                dataIndex: 'normalEthVolume',
                width: "200px",
                render: (value, record) => {
                    return <Input type={'number'} required={true} min={0} style={{ width: '100%' }} value={changedInputField('normalEthVolume', value, record)} onChange={(e) => this.onInputChange(e, 'normalEthVolume', record)} />
                }
            },
        ];

        return (
            <div>
                <PageHeader
                    className={'main-page-content'}
                    title={<Title>Exchanges</Title>}
                    extra={[
                        <Button key={1} type="primary" disabled={loading} onClick={this.onAddDrawerShow}>
                            Add
                        </Button>,
                        <Badge key={2} count={forUpdate.length} overflowCount={99}>
                            <Button type="primary" disabled={!forUpdate.length || loading} onClick={this.onUpdateDrawerShow}>
                                Update
                            </Button>
                        </Badge>
                    ]}
                >
                    <div className="site-card-border-less-wrapper">
                        {
                            !!error &&
                            <Alert message={error} type="error" banner />
                        }
                        <Table
                            columns={columns}
                            dataSource={data}
                            size="small"
                            loading={loading}
                            pagination={data.length <= 10 ? false : { pageSize: 50 }}
                        />
                    </div>
                    <Drawer
                        title="Update"
                        width={720}
                        onClose={this.onUpdateDrawerClose}
                        visible={visibleUpdateDrawer}
                        bodyStyle={{ paddingBottom: 80 }}
                    >
                        <List
                            className="update-list"
                            itemLayout="horizontal"
                            dataSource={forUpdate}
                            renderItem={item => (
                                <List.Item
                                    actions={[<a key="update-list-delete" onClick={() => this.deleteOneUpdate(item.exchangeId)}>delete</a>]}
                                >
                                    <List.Item.Meta
                                        title={<b>{item.exchangeId} | {item.exchangeName}</b>}
                                        description={JSON.stringify(item.data)}
                                    />
                                    <hr />
                                </List.Item>
                            )}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 16px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={this.onClearUpdates} type="danger" style={{ float: 'left' }}>
                                Clear
                            </Button>
                            <Button onClick={this.onUpdateDrawerClose} style={{ marginRight: 8 }}>
                                Cancel
                            </Button>
                            <Button onClick={this.onUpdate} type="primary">
                                Submit
                            </Button>
                        </div>
                    </Drawer>
                    <AddExchangeDrawer
                        user={this.props.user}
                        visible={visibleAddDrawer}
                        onClose={this.onAddDrawerClose}
                        callback={this.props.parentUpdate}
                    />
                </PageHeader>
            </div>
        )
    }
}

export default Exchanges;